export const BOOKING_STATUS = {
	ALLOCATE: {
		text: 'รอจัดสรรคนเข้ารับ',
		color: 'success',
		value: 'ALLOCATE',
	},
	APPROVE: {
		text: 'ยืนยันสำเร็จ',
		color: 'success',
		value: 'APPROVE',
	},
	CANCEL: {
		text: 'ถูกยกเลิก',
		color: 'danger',
		value: 'CANCEL',
	},
	ASSIGNED: {
		text: 'มีคนพร้อมเข้ารับ',
		color: 'warning',
		value: 'ASSIGNED',
	},
	COMPLETED: {
		text: 'รถเข้ารับสำเร็จ',
		color: 'success',
		value: 'COMPLETED',
	},
}
