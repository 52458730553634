import axios from '../instance'
import { IParamsBookingList } from './booking.type'

export const getBookingList = async (params: IParamsBookingList) => {
	try {
		const response = await axios.get('/admin/booking', { params: {...params}})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateBookingStatusById = async (bookingId: string, dataBody: { status: string}) => {
	try {
		const response = await axios.put(`/admin/booking/${bookingId}`, dataBody)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}


export const updateBookingSync = async (): Promise<any> => {
	try {
		const response = await axios.post('/admin/booking-sync')
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
